<script setup>
import appAxios from '@/libs/app-axios'
import { deepClone, keys } from '@/libs/utils'
import axios from 'axios'
import { reactive, defineProps, defineEmits, watchEffect } from 'vue'

/** @type {FormState} */
const initialFormState = {
  data: {
    area: '',
    task: '',
    isFloor: false,
    standard: '',
  },
  errors: {},
  isLoading: false,
}

const formState = reactive(deepClone(initialFormState))
/** @type {Props} */
const props = defineProps(['editId'])
const emits = defineEmits(['on-cancel', 'on-success'])

/**
 * Description
 * @param {AbortController} controller
 */
const getPMTemplateItem = async controller => {
  try {
    if (!props.editId) return
    /** @type {import('axios').AxiosResponse<PMTemplateItem>} */
    const { status, data } = await appAxios().get(`/preventive-maintenance-template-items/${props.editId}`, { signal: controller.signal })
    if (status !== 200) throw new Error('oops something went wrong')
    keys(formState.data).forEach(key => (formState.data[key] = data[key]))
  } catch (e) {
    console.error(e)
  }
}

watchEffect(onCleanup => {
  if (!props.editId) return
  const controller = new AbortController()
  getPMTemplateItem(controller)
  onCleanup(() => controller.abort())
})

const reset = () => {
  const initialData = deepClone(initialFormState)
  initialData.data.area = formState.data.area
  initialData.data.standard = formState.data.standard
  Object.assign(formState, initialData)
}

const onCancelHandler = () => {
  reset()
  emits('on-cancel')
}

const serverMutation = async () => {
  const { searchParams } = new URL(window.location.href)
  if (props.editId) {
    const response = await appAxios().put(`/preventive-maintenance-template-items/${props.editId}`, formState.data)
    formState.data = response.data
    onCancelHandler()
    return response
  }
  const pmTemplateId = parseInt(searchParams.get('e'), 10)
  if (!pmTemplateId == null) throw new Error('query string e not found')
  return appAxios().post(`/preventive-maintenance-templates/${pmTemplateId}/items`, { ...formState.data })
}


const onSubmitHandler = async e => {
  try {
    e.preventDefault()
    formState.errors = {}
    formState.isLoading = true
    await serverMutation()
    emits('on-success')
    reset()
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const { data } = e.response
      if ('errors' in data) {
        const { errors } = data
        keys(formState.data)
          .forEach(key => errors[key] != null && (formState.errors[key] = errors[key]))
      }
    }
  } finally {
    formState.isLoading = false
  }
}

</script>
<style lang="sass" scoped>
.list-items-form
  display: flex
  flex-direction: column
  gap: 10px
  .edit-actions
    display: flex
    gap: 10px
    flex: 1
    *
      flex: 1
</style>
<template>
  <form @submit="onSubmitHandler">
    <div class="list-items-form">
      <v-switch v-model="formState.data.isFloor" label="Is Floor" hide-details="auto"></v-switch>
      <v-text-field :disabled="formState.data.isFloor" outlined dense :error-messages="formState.errors.area" hide-details="auto"
        v-model="formState.data.area" label="Area"></v-text-field>
      <v-text-field outlined dense :error-messages="formState.errors.task" hide-details="auto"
        v-model="formState.data.task" label="Task"></v-text-field>
      <v-text-field outlined dense :error-messages="formState.errors.standard" hide-details="auto"
        v-model="formState.data.standard" label="Standard"></v-text-field>
      <div v-if="props.editId" class="edit-actions">
        <v-btn type="submit" color="warning" dark :loading="formState.isLoading">Update</v-btn>
        <v-btn type="button" color="error" dark @click="onCancelHandler">Cancel</v-btn>
      </div>
      <v-btn v-else type="submit" :disable="formState.isLoading" :loading="formState.isLoading" dark
        color="primary">Submit</v-btn>
    </div>
  </form>
</template>
<script>
/**
 * @typedef {Object} FormState
 * @property {boolean} isLoading
 * @property {FormStateData} data
 * @property {FormStateErrors} errors
 * 
 * @typedef {Object} FormStateErrors
 * @property {string[]} [area]
 * @property {string[]} [task]
 * @property {string[]} [standard]
 * 
 * @typedef {Object} FormStateData
 * @property {string} area
 * @property {string} task
 * @property {string} standard
 * @property {boolean|null} isFloor
 * 
 * @typedef {Object} Props
 * @property {PMTemplateItem['id']} [editId]
 * 
 * @typedef {import('./Index.vue').PreventiveMaintenanceTemplateItem} PMTemplateItem 
 */
</script>

