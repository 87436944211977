<script setup>
import { onBeforeMount, ref } from 'vue'
import useSearchParamRouter from '@/libs/useSearchParamRouter'
import PreventiveMaintenanceTemplateForm from './Form.vue'
import PreventiveMaintenanceTemplateIndex from './Index.vue'
import PMTemplateListItem from './items/Template.vue'

const searchParamRouter = useSearchParamRouter()
/** @type {import('vue').Ref<number|null>} */
const editId = ref(null)
const pmTemplateIndexRef = ref(null)
const rerenderListItem = ref(0)

onBeforeMount(() => {
  const { searchParams } = new URL(window.location.href)
  const editItem = searchParams.get('e')
  if (!editItem) return
  editId.value = parseInt(editItem, 10)
})

const onCancelHandler = () => {
  const { searchParams } = new URL(window.location.href)
  searchParams.delete('e')
  searchParamRouter.replaceState(searchParams)
  editId.value = null
}
/**
 * Description
 * @param {PreventiveMaintenanceTemplate['id']} itemId
 * @returns {void}
 */
const onEditHandler = (itemId) => {
  const { searchParams } = new URL(window.location.href)
  console.log(itemId)
  searchParams.set('e', String(itemId))
  searchParamRouter.replaceState(searchParams)
  editId.value = itemId
  rerenderListItem.value++
}

const onSuccessHandler = () => {
  pmTemplateIndexRef.value?.getPreventiveMaintenanceTemplates?.()
}
/**
 * @typedef {import('./Index.vue').PreventiveMaintenanceTemplate} PreventiveMaintenanceTemplate
 */
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.box-bubble {
  border: 1px solid #cfcdcd;
  padding: 10px;
  border-radius: 10px;
}

.pm-template {
  display: flex;
  gap: 10px;
}

.pm-template-index {
  flex: 1;
}

.pm-template-list-item {
}
</style>
<template>
  <div class="container">
    <div class="pm-template">
      <div class="pm-template-form box-bubble">
        <preventive-maintenance-template-form
          :edit-id="editId"
          @on-cancel="onCancelHandler"
          @on-success="onSuccessHandler"
        />
      </div>
      <div class="pm-template-index box-bubble">
        <preventive-maintenance-template-index ref="pmTemplateIndexRef" @on-edit="onEditHandler" />
      </div>
    </div>
    <div class="pm-template-list-item">
      <PMTemplateListItem :key="rerenderListItem" v-if="editId" />
    </div>
  </div>
</template>
