
export default function useSearchParamRouter() {
  const { pathname } = location

  /**
   * Description
   * @param {URLSearchParams} searchParams
   * @returns {void}
   */
  const replaceState = searchParams => {
    window.history.replaceState({}, '', `${pathname}?${searchParams}`)
  }

  return {
    replaceState,
  }
}
