<script setup>
import appAxios from '@/libs/app-axios'
import { deepClone } from '@/libs/utils'
import { defineEmits, defineExpose, onBeforeMount, reactive } from 'vue'

/** @type {{
 * isLoading: boolean
 * data: PreventiveMaintenanceTemplateItem[]
 * }} */
const tableInitialState = {
  isLoading: false,
  data: [],
}

const emits = defineEmits(['on-edit'])
const tableState = reactive(deepClone(tableInitialState))
/** @type {Array<import('vuetify').DataTableHeader<PreventiveMaintenanceTemplateItem>>} */
const headers = [
  { text: '#', value: 'index' },
  {
    text: 'Area',
    value: 'area',
  },
  {
    text: 'Task',
    value: 'task',
  },
  {
    text: 'Standard',
    value: 'standard',
  },
  {
    text: 'Action',
    value: 'action',
  },
]

const getPMTemplateItems = async () => {
  try {
    tableState.isLoading = true
    const { searchParams } = new URL(window.location.href)
    const templateId = searchParams.get('e')
    if (!templateId) throw new Error('e is undefined')
    /** @type {import('axios').AxiosResponse<PreventiveMaintenanceTemplateItem[]>} */
    const { status, data } = await appAxios().get(
      `/preventive-maintenance-templates/${templateId}/items`
    )
    if (status !== 200) throw new Error('oops something went wrong, retying in 3 seconds...')
    tableState.data = data
  } catch (e) {
    setTimeout(() => {
      getPMTemplateItems()
    }, 3000)
    console.error(e.message)
  } finally {
    tableState.isLoading = false
  }
}

/**
 * Description
 * @param {PreventiveMaintenanceTemplateItem['id']} itemId
 */
const onEditHandler = (itemId) => {
  emits('on-edit', itemId)
}

defineExpose({
  getPMTemplateItems,
})

onBeforeMount(() => {
  getPMTemplateItems()
})
</script>
<style lang="sass">
.items-container
  display: flex
  .data-table
    .action
      display: flex
      gap: 10px
</style>
<template>
  <div class="items-container">
    <v-data-table
      hide-default-footer
      :items-per-page="-1"
      class="data-table"
      :headers="headers"
      :items="tableState.data"
      :loading="tableState.isLoading"
    >
      <template #item.action="{ item }">
        <div class="action">
          <v-icon color="yellow darken-3" @click="() => onEditHandler(item.id)">mdi-pencil</v-icon>
        </div>
      </template>
      <template #item.index="{ index }">
        <div>
          {{ index + 1 }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  name: 'PreventiveMaintenanceTemplateItemIndex',
}
/**
 * @typedef {Object} PreventiveMaintenanceTemplateItem
 * @property {number} id
 * @property {string} area
 * @property {string} task
 * @property {string} standard
 * @property {string} createdAt
 * @property {string} updatedAt
 */
</script>
